.cm-form {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 450px;
    background: #119983;
    padding: 4rem;
    border-radius: 12px;
    box-shadow: -20px 15px 10px rgba(13, 156, 106, 0.25);
    z-index: 99;
    display: flex;
    flex-direction: column;
    align-items: center;

    .form-close {
        font-size: 3rem;
        color: #11c687;
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
        cursor: pointer;
    }

    &>h1 {
        color: white;
        font-size: 3rem;
        margin-bottom: 2rem;
    }

    .form-elements {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        label {
            width: 100%;
            color: rgba(255, 255, 255, 0.6);
            font-size: 1.5rem;
            display: flex;
            justify-content: space-between;
            align-items: center;

            &>input,
            &>textarea {
                color: #119983;
                font-weight: bold;
                line-height: 1.5rem;
                width: 300px;
                margin: 0.5rem;
            }
        }
    }

    .form-btns {
        width: 100%;
    }
}

/* Mobile Landscape */
@media (max-device-width: 1023px) and (orientation: landscape) {
    .cm-form {
        min-height: 100vh;
        min-width: 100vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 0;
        padding: 0;

        .form-close {
            display: none;
        }

        &>h1 {
            margin: -1rem auto 0.5rem;
            text-shadow: 2px 2px 5px #09091a;
            width: 100vw;
        }

        .form-elements {
            width: 100%;
            margin-bottom: 40px;

            label {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin: 0;
                padding: 0;
                box-shadow: none;
                text-align: center;

                &>input,
                &>textarea {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    height: 30px;
                }
            }
        }

        .form-btns {
            margin: 0 auto;
            display: flex;
            position: absolute;
            bottom: 5px;
        }
    }
}

/* Tablet Landscapes */
@media only screen and (min-device-width: 1024px) and (max-device-width: 1035px) and (orientation: landscape) {
    .cm-form {
        min-height: 100vh;
        min-width: 100vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 0;
        padding: 0;

        .form-close {
            display: none;
        }

        &>h1 {
            font-size: 6rem;
            text-shadow: 2px 2px 5px #09091a;
            width: 100vw;
        }

        .form-elements {
            width: 100%;
            margin-bottom: 40px;

            label {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin: 0;
                padding: 0;
                box-shadow: none;
                text-align: center;

                &>input {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    height: 30px;
                    font-size: 2rem;
                }

                &>textarea {
                    height: 200px;
                    width: 400px;
                    font-size: 3rem;
                }
            }
        }

        .form-btns {
            margin: 0 auto;
            display: flex;
            justify-content: center;
            align-items: flex-end;
            position: absolute;
            bottom: 50px;
        }
    }
}

/* Mobile Form */
@media only screen and (min-device-width: 320px) and (max-device-width: 767px) and (orientation: portrait) {
    .cm-form {
        min-height: 100vh;
        min-width: 100vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 0;
        padding: 0;

        .form-close {
            display: none;
        }

        &>h1 {
            margin: 0 auto;
            margin-bottom: 1rem;
            text-shadow: 2px 2px 5px #09091a;
            width: 100vw;
        }

        .form-elements {
            width: 100%;

            label {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin: 0;
                padding: 0;
                box-shadow: none;
                text-align: center;

                &>input,
                &>textarea {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }
            }
        }

        .form-btns {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
        }
    }
}