/*---------------------------------------*/
/*              Navigation               */
/*---------------------------------------*/

.nav-wrapper {
  height: 100px;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  z-index: 1;
  position: absolute;
  @include no-select;

  .link-effect {
    display: flex;
    flex-direction: column;

    // Navbar links
    .links {
      color: $alt;
      margin-right: 5.5rem;
      border-bottom: 1px solid $alt;
      font-family: $roboto;

      .nav__btn {
        @include reset-btn;
      }

      .nav__link {
        margin: 0 1.5rem;
        font-size: 1.5rem;
        color: $alt;
        text-decoration: none;
      }
    }

    // Mirror Effect
    .link__mirror {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      margin-right: 5.5rem;

      &>p {
        margin: 0 1.5rem;
        font-size: 1.5rem;
        color: $opaque-alt;
        filter: blur(2px);
        -moz-transform: scaleY(-1);
        -o-transform: scaleY(-1);
        -webkit-transform: scaleY(-1);
        transform: scale(1, -1);
      }
    }
  }
}

/* The DREADED iPhone 5/SE */
@media only screen and (min-device-width : 320px) and (max-device-width : 568px) {
  .nav-wrapper {
    height: 220px;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .link-effect {
      margin: 0 auto;

      .links {
        margin: 0 auto;
        text-align: center;

        .nav__link {
          margin-right: 1rem;
          font-size: 1.5rem;
        }
      }

      .link__mirror {
        margin: 0 auto;

        &>p {
          margin-right: 1rem;
          font-size: 1.5rem;
        }
      }
    }
  }
}

/* Extra small devices (phones, up to 480px) */
@media (min-width: 569px) and (max-width: 767px) {
  .nav-wrapper {
    height: 160px;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .link-effect {
      margin: 0 auto;

      .links {
        margin: 0 auto;
        text-align: center;

        .nav__link {
          margin-right: 1rem;
          font-size: 2rem;
        }
      }

      .link__mirror {
        margin: 0 auto;

        &>p {
          margin-right: 1rem;
          font-size: 2rem;
        }
      }
    }
  }
}

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991px) {
  .nav-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 50px;

    .link-effect {
      margin-left: 2rem;

      .links {
        margin: 0 auto;
        display: flex;
        width: 100%;

        .nav__btn {
          margin: 0 auto;
        }
      }

      .link__mirror {
        margin: 0 auto;
      }
    }
  }
}

/* tablets/desktops and up ----------- */
@media (min-width: 992px) and (max-width: 1199px) {
  .nav-wrapper {
    margin-top: 30px;
  }
}