/*---------------------------------------*/
/*              Portfolio                */
/*---------------------------------------*/
.portfolio-card-wrapper {
  margin-top: 100px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  z-index: 1;
  font-family: $roboto;
  flex-wrap: nowrap;
  white-space: nowrap;
  overflow-x: scroll;
  width: auto;
  -webkit-overflow-scrolling: touch;

  .profile-card {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    min-width: fit-content;
    height: 100vh;
    max-height: 980px;
    margin-right: 2rem;

    .portfolio-hero-img {
      padding: 2rem;

      .portfolio-img {
        height: 400px;
        width: 400px;
        border-radius: 12px;
        box-shadow: 1px 4px 6px 0 rgba(0, 0, 0, 0.25),
          1px 6px 12px 0 rgba(0, 0, 0, 0.3);
        transition: box-shadow 0.3s;
        cursor: pointer;

        &:hover {
          box-shadow: 2px 8px 12px 0 rgba(0, 0, 0, 0.55),
            2px 12px 24px 0 rgba(0, 0, 0, 0.6);
        }
      }
    }

    .portfolio-hero-text {
      font-size: 5rem;
      color: white;
      text-shadow: 2px 2px 5px #09091a;
      padding: 1rem;
      display: flex;
      flex-direction: column;

      .top-text {
        &>h1 {
          font-family: $cabin;
        }

        &>h3 {
          font-size: 2.25rem;
          padding: 0.2rem 0.4rem 0.8rem 0;
        }
      }

      .notable {
        margin: 1rem 0;

        &>h3 {
          font-size: 2.25rem;
          padding: 0.2rem;
          text-align: center;
          text-decoration: underline;
        }

        &>ul {
          display: flex;
          flex-direction: column;
          align-items: center;

          &>li {
            width: 100%;
            font-size: 1.7rem;
            padding: 1rem 0;
          }
        }
      }
    }

    .button {
      padding: 1rem;
    }
  }
}

/* The DREADED iPhone 5/SE */
@media screen and (device-width : 320px) {
  .portfolio-card-wrapper {
    overflow-x: scroll;
    overflow-y: hidden;

    .profile-card {
      .portfolio-hero-img {
        .portfolio-img {
          height: 250px;
          width: 250px;
        }
      }

      .portfolio-hero-text {
        .top-text {
          &>h1 {
            display: none;
          }

          &>h3 {
            display: none;
          }
        }

        .notable {
          &>h3 {
            display: none;
          }

          &>ul {
            &>li {
              display: none;
            }
          }
        }
      }

      .button {
        display: none;
      }
    }
  }
}

/* Landscape */
@media screen and (max-device-width : 1024px) and (orientation: landscape) {
  .portfolio-card-wrapper {
    margin-top: 150px;
    overflow-x: hidden;
    overflow-y: scroll;
    flex-direction: column;
    justify-content: center;
    align-items: center;


    .profile-card {
      margin: 0 auto;
      height: 100%;

      .portfolio-hero-img {
        .portfolio-img {
          height: 300px;
          width: 300px;
        }
      }

      .portfolio-hero-text {
        .top-text {
          &>h1 {
            display: none;
          }

          &>h3 {
            display: none;
          }
        }

        .notable {
          &>h3 {
            display: none;
          }

          &>ul {
            &>li {
              display: none;
            }
          }
        }

        .button {
          padding: 0;
        }
      }
    }
  }
}

/* Small Devices (phones 320px - 568px) */
@media only screen and (min-device-width : 321px) and (max-device-width : 568px) {
  .portfolio-card-wrapper {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
    height: 100%;

    .profile-card {
      min-height: 100%;
      min-width: 100%;
      margin: 0 auto;
      border-bottom: 2px solid $opaque-alt;

      &:first-child {
        margin-top: 100px;
      }

      .portfolio-hero-img {
        padding: 0.5rem;
        height: 330px;

        .portfolio-img {
          height: 250px;
          width: 250px;
        }
      }

      .portfolio-hero-text {
        font-size: 4rem;
        color: white;
        padding: 0;

        .top-text {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          height: 100%;
          margin: 0 auto;

          &>h1 {
            font-family: $cabin;
          }

          &>h3 {
            font-size: 1.25rem;
            margin: 0rem 0 1rem;
          }
        }

        .notable {
          display: flex;
          flex-direction: column;
          height: 100%;
          margin: 1rem 0;

          &>h3 {
            font-size: 2.25rem;
            padding: 0 0 2.5rem;
          }

          &>ul {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;

            &>li {
              font-size: 1.2rem;
              padding: 0rem 0 1rem;
            }
          }
        }
      }

      .button {
        padding: 1rem;
      }
    }
  }
}

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991px) {
  .portfolio-card-wrapper {
    margin-top: 150px;

    .profile-card {
      justify-content: flex-start;
      align-items: center;

      .portfolio-hero-img {

        .portfolio-img {
          height: 300px;
          width: 300px;
        }
      }

      .portfolio-hero-text {
        .notable {
          &>ul {
            &>li {
              padding: 0.15rem;
            }
          }
        }
      }
    }
  }
}

/* tablets/desktops and up ----------- */
@media (min-width: 992px) and (max-width: 1199px) {
  .portfolio-card-wrapper {
    .profile-card {
      max-height: 100vh;
      min-width: 700px;
    }
  }
}

// Large Tablets (iPad Pro)
@media (min-width: 1200px) and (max-width: 1366px) {
  .portfolio-card-wrapper {
    margin-top: 70px;

    .profile-card {
      .portfolio-hero-text {
        .notable {
          &>ul {
            &>li {
              padding: 0rem;
            }
          }
        }
      }
    }
  }
}