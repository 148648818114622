/*---------------------------------------*/
/*                Home                   */
/*---------------------------------------*/

.home-wrapper {
  height: 100vh;

  // Background Image
  .background {
    height: 100vh;
    width: 100vw;
    object-fit: cover;
    background-attachment: fixed;
    background-position: center;
    position: relative;
    background-repeat: no-repeat;
  }

  // Text Component
  .hero-text-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    width: 100%;
    height: 100vh;
    text-shadow: 2px 2px 4px $dark;

    .animated-div {
      z-index: 1;
      width: 100vw;

      &>h1 {
        font-family: $pacifico;
        font-size: 8rem;
      }

      &>h2 {
        font-family: $pacifico;
        font-size: 9rem;
        padding: 3.5rem;
      }

      &>h3 {
        margin-top: 1.5rem;
        padding-bottom: 0.5rem;
        font-size: 3rem;
        font-family: $cabin;
      }
    }

    // Links 
    .home-links {
      display: flex;
      justify-content: center;
      align-items: center;
      position: fixed;
      bottom: 50px;
      left: 0;
      width: 100vw;
      height: 60px;

      .home__links {
        @include reset-btn;
        color: white;
        margin: auto 2rem;
        font-family: $cabin;
        font-size: 2rem;
        outline: none;
        background: none;
        border: none;
        text-shadow: 2px 2px 4px $dark;
        cursor: pointer;
        border-bottom: 2px solid white !important;
      }
    }
  }
}

/* Iphone 5/SE Landscape */
@media screen and (max-height:320px) {
  .home-wrapper {
    .hero-text-wrapper {
      .animated-div {
        &>h3 {
          display: none;
        }
      }
    }
  }
}

@media (orientation: landscape) {
  .home-wrapper {
    .hero-text-wrapper {
      .home-links {
        bottom: 0px;
      }
    }
  }
}

/* The DREADED iPhone 5/SE */
@media only screen and (min-device-width : 320px) and (max-device-width : 568px) {
  .home-wrapper {
    .hero-text-wrapper {
      top: 40%;

      .animated-div {

        &>h1 {
          font-size: 4rem;
        }

        &>h2 {
          padding: 0.5rem 0 2.3rem;
          font-size: 4.5rem;
        }

        &>h3 {
          margin: 0 1rem;
          font-size: 2rem;
        }
      }

      .home-links {
        margin: 0 auto;

        .home__links {
          @include reset-btn;
          margin: 0 1.5rem;
          font-size: 1.6rem;
        }
      }
    }
  }
}

/* Extra small devices (phones, up to 480px) */
@media (min-width: 569px) and (max-width: 767px) {
  .home-wrapper {
    .hero-text-wrapper {
      top: 40%;

      .animated-div {

        &>h1 {
          font-size: 4rem;
        }

        &>h2 {
          padding: 0.5rem 0 2.3rem;
          font-size: 4.5rem;
        }

        &>h3 {
          margin: 0 1rem;
          font-size: 2rem;
        }
      }

      .home-links {
        margin: 0 auto;

        .home__links {
          @include reset-btn;
          margin: 0 1.5rem;
          font-size: 1.6rem;
        }
      }
    }
  }
}

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991px) {
  .home-wrapper {
    .hero-text-wrapper {
      top: 40%;

      .animated-div {

        &>h1 {
          font-size: 4.5rem;
        }

        &>h2 {
          padding: 0.4rem 0 2.6rem;
          font-size: 5.5rem;
        }

        &>h3 {
          margin: 0 1rem;
          font-size: 2.1rem;
        }
      }

      .home-links {
        margin: 0 auto;

        .home__links {
          margin: 0 1.5rem;
          font-size: 2rem;
        }
      }
    }
  }
}

/* tablets/desktops and up ----------- */
@media (min-width: 992px) and (max-width: 1199px) {
  .home-wrapper {
    .hero-text-wrapper {
      top: 40%;

      .animated-div {

        &>h1 {
          font-size: 6rem;
        }

        &>h2 {
          font-size: 7rem;
          padding: 0.5rem 0 3rem;
        }

        &>h3 {
          margin: 0.5rem auto;
          font-size: 2.6rem;
        }
      }
    }
  }
}