/*---------------------------------------*/
/*             Title/General             */
/*---------------------------------------*/

.cv-wrapper {
  top: 100px;
  position: absolute;
  color: $dark;
  margin: 0rem 5rem;
  font-family: $cabin;

  .title-text {
    text-align: center;
    margin: 1rem 0;
    font-family: $roboto;

    &>h1 {
      font-size: 5rem;
      padding: 0.7rem 0;
      color: $alt;
      font-family: $marker;
    }

    &>h2 {
      font-size: 2.5rem;
      color: $dark;
    }
  }

  em {
    color: $alt;
  }

  .main-section {
    width: 80%;
    float: right;
  }
}

/*---------------------------------------*/
/*             Aside                     */
/*---------------------------------------*/

.aside {
  width: 20%;
  height: 100%;
  float: left;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-end;

  .aside-section {
    margin: 0rem 0.5rem 1rem;
    text-align: end;
    border-bottom: 1px solid $opaque;

    &>h2 {
      font-size: 2.5rem;
      color: $main;
      margin-bottom: 0.5rem;
    }

    &>p {
      color: $alt;
      font-size: 1.5rem;
      margin-bottom: 0.5rem;
    }
  }

  .icons {
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;

    .linkedin {
      color: $linkedIn;
    }

    .github {
      color: $github;
    }

    &>button {
      @include reset-btn;
      color: $mail;
      margin: 0.5rem;
    }

    &>a {
      margin: 0.5rem;
    }
  }

  .lang {
    padding: 0 0 0.5rem;
  }

  .spoken-notions {

    &>p {
      color: $alt;
      font-size: 1.5rem;
      line-height: 1.6rem;
      padding-bottom: 0.5rem;
    }
  }
}

/*---------------------------------------*/
/*              Interests                */
/*---------------------------------------*/

.interests-section {
  margin: 0 1rem 1rem;

  &>h3 {
    font-size: 3rem;
    font-family: $roboto;
  }

  &>p {
    font-size: 1.4rem;
    line-height: 2.5rem;
  }

  p>em {
    font-size: 1.7rem;
    line-height: 2.5rem;
    color: $main;
    vertical-align: bottom;
  }
}

/*---------------------------------------*/
/*             Experience                */
/*---------------------------------------*/

.experience-section {
  margin: 0 1rem 1rem;

  &>h3 {
    font-size: 3rem;
    font-family: $roboto;
  }

  .cv-experience {
    margin-bottom: 0.5rem;
    border-bottom: 1px solid $opaque-alt;

    &:last-child {
      border-bottom: none;
    }

    &>p {
      font-size: 1.4rem;
    }

    p>em {
      font-size: 1.7rem;
      line-height: 2.5rem;
    }

    .exp-title {
      color: $main;
      margin-bottom: 0.5rem;
    }

    .exp-li {
      font-size: 1.5rem;
      line-height: 2rem;
    }

    .duration {
      margin-top: 0.25rem;
      color: $opaque;
      font-weight: bold;
      font-size: 1.5rem;
      padding-bottom: 1.2rem;
    }

    &:last-child {
      .duration {
        padding-bottom: 0;
      }
    }
  }
}

/*---------------------------------------*/
/*              Education                */
/*---------------------------------------*/

.education-section {
  margin: 0 1rem 1rem;

  &>h3 {
    font-size: 3rem;
    font-family: $roboto;
  }

  .cv-edu {
    margin: 0.5rem 0 1rem;

    .cv-school {
      display: flex;
      align-items: center;

      &>h4 {
        font-size: 2rem;
        text-align: center;
        margin-right: 1.5rem;
        color: $main;
      }

      &>h5>em {
        font-size: 1.5rem;
        color: $alt;
      }

      .cap {
        color: $alt;
        margin-right: 0.5rem;
      }
    }

    .cv-edu-mid {
      font-size: 1.5rem;

      &>p {
        margin: 0.5rem 0 0;
      }

      p>em {
        font-size: 1.7rem;
      }
    }
  }
}

/*---------------------------------------*/
/*            Media Queries              */
/*---------------------------------------*/

/* The DREADED iPhone 5/SE */
@media only screen and (min-device-width : 320px) and (max-device-width : 568px) {
  .cv-wrapper {
    margin: 0 auto;
    margin-top: 80px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    .title-text {
      &>h1 {
        font-size: 2.5rem;
      }

      &>h2 {
        font-size: 2rem;
      }
    }

    // Aside
    .aside {
      width: 100%;
      margin: 0 auto;

      .aside-section {
        width: 90%;
        text-align: center;
        margin: 0.5rem auto;
      }
    }

    .main-section {
      width: 100%;

      .interests-section {
        margin: 1rem 0.5rem 1rem;
        padding-bottom: 1.5rem;
        border-bottom: 2px solid $alt;

        &>h3 {
          font-size: 4rem;
          margin: 2rem auto;
        }
      }

      .experience-section {
        margin: 0 0.5rem 1rem;
        padding-bottom: 1rem;
        border-bottom: 2px solid $alt;

        &>h3 {
          font-size: 4rem;
          margin: 2rem auto;
        }

        .cv-experience {
          margin-bottom: 2rem;

          &:last-child {
            margin-bottom: 0;
          }

          &>p {
            font-size: 2rem;
            padding-bottom: 1rem;
          }

          p>em {
            font-size: 2.5rem;
            line-height: 3.2rem;
          }

          .duration {
            margin-top: 1rem;
            font-size: 2rem;
            padding-bottom: 1.5rem;
          }
        }
      }

      .education-section {
        margin: 1rem 0.5rem 0;

        &>h3 {
          font-size: 4rem;
          margin: 2rem auto;
        }

        .cv-edu {
          padding-bottom: 1.5rem;
          border-bottom: 1px solid $opaque;
          width: 95%;
          margin: 0 auto;

          &:last-child {
            border-bottom: none;
            padding-bottom: 0;
          }

          .cv-school {
            display: flex;
            flex-direction: column;
            margin: 0.5rem auto;

            &>h4 {
              margin: 0.5rem auto;
            }

            &>h5>em {
              margin: 0.5rem auto;
            }

            .cap {
              margin: 0.5rem auto;
            }
          }
        }
      }
    }
  }
}

/* Extra small devices (phones, up to 480px) */
@media (min-width: 569px) and (max-width: 767px) {
  .cv-wrapper {
    margin: 0 auto;
    margin-top: 80px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    .title-text {
      &>h1 {
        font-size: 3rem;
      }

      &>h2 {
        font-size: 2.5rem;
      }
    }

    // Aside
    .aside {
      width: 100%;
      margin: 0 auto;

      .aside-section {
        width: 90%;
        text-align: center;
        margin: 0.5rem auto;

        &>h2 {
          font-size: 2.5rem;
        }

        &>p {
          font-size: 2rem;
        }

        .spoken-notions {
          &>p {
            font-size: 2rem;
            margin-bottom: 0.5rem;
          }
        }
      }
    }

    .main-section {
      width: 100%;

      .interests-section {
        margin: 1rem 0.5rem 1rem;
        padding-bottom: 1.5rem;
        border-bottom: 2px solid $alt;

        &>h3 {
          font-size: 4rem;
          margin: 2rem auto;
        }
      }

      .experience-section {
        margin: 0 0.5rem 1rem;
        padding-bottom: 1rem;
        border-bottom: 2px solid $alt;

        &>h3 {
          font-size: 4rem;
          margin: 2rem auto;
        }

        .cv-experience {
          margin-bottom: 2rem;

          &:last-child {
            margin-bottom: 0;
          }

          &>p {
            font-size: 2rem;
            padding-bottom: 1rem;
          }

          p>em {
            font-size: 2.5rem;
            line-height: 3.2rem;
          }

          .duration {
            margin-top: 1rem;
            font-size: 2rem;
            padding-bottom: 1.5rem;
          }
        }
      }

      .education-section {
        margin: 1rem 0.5rem 0;

        &>h3 {
          font-size: 4rem;
          margin: 2rem auto;
        }

        .cv-edu {
          padding-bottom: 1.5rem;
          border-bottom: 1px solid $opaque;
          width: 95%;
          margin: 0 auto;

          &:last-child {
            border-bottom: none;
            padding-bottom: 0;
          }

          .cv-school {
            display: flex;
            flex-direction: column;
            margin: 0.5rem auto;

            &>h4 {
              margin: 0.5rem auto;
            }

            &>h5>em {
              margin: 0.5rem auto;
            }

            .cap {
              margin: 0.5rem auto;
            }
          }
        }
      }
    }
  }
}

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991px) {
  .cv-wrapper {
    margin: 0 auto;
    margin-top: 80px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    .title-text {
      &>h1 {
        font-size: 3rem;
      }

      &>h2 {
        font-size: 2.5rem;
      }
    }

    // Aside
    .aside {
      width: 100%;
      margin: 0 auto;

      .aside-section {
        width: 90%;
        text-align: center;
        margin: 0.5rem auto;
      }
    }

    .main-section {
      width: 100%;

      .interests-section {
        margin: 1rem 0.5rem 1rem;
        padding-bottom: 1.5rem;
        border-bottom: 2px solid $alt;

        &>h3 {
          font-size: 4rem;
          margin: 2rem auto;
        }
      }

      .experience-section {
        margin: 0 0.5rem 1rem;
        padding-bottom: 1rem;
        border-bottom: 2px solid $alt;

        &>h3 {
          font-size: 4rem;
          margin: 2rem auto;
        }

        .cv-experience {
          margin-bottom: 2rem;

          &:last-child {
            margin-bottom: 0;
          }

          &>p {
            font-size: 2rem;
            padding-bottom: 1rem;
          }

          p>em {
            font-size: 2.5rem;
            line-height: 3.2rem;
          }

          .duration {
            margin-top: 1rem;
            font-size: 2rem;
            padding-bottom: 1.5rem;
          }
        }
      }

      .education-section {
        margin: 1rem 0.5rem 0;

        &>h3 {
          font-size: 4rem;
          margin: 2rem auto;
        }

        .cv-edu {
          padding-bottom: 1.5rem;
          border-bottom: 1px solid $opaque;
          width: 95%;
          margin: 0 auto;

          &:last-child {
            border-bottom: none;
            padding-bottom: 0;
          }

          .cv-school {
            display: flex;
            flex-direction: column;
            margin: 0.5rem auto;

            &>h4 {
              margin: 0.5rem auto;
            }

            &>h5>em {
              margin: 0.5rem auto;
            }

            .cap {
              margin: 0.5rem auto;
            }
          }
        }
      }
    }
  }
}

/* tablets/desktops and up ----------- */
@media (min-width: 992px) and (max-width: 1199px) {
  .cv-wrapper {
    .title-text {
      &>h1 {
        font-size: 4rem;
      }

      &>h2 {
        font-size: 2.8rem;
      }
    }

    // Aside
    .aside {
      .aside-section {
        &>h2 {
          font-size: 2rem;
        }

        .icons {
          .linkedin {
            width: 40px;
            height: 40px;
          }

          .github {
            width: 40px;
            height: 40px;
          }

          &>button>:only-child {
            width: 40px;
            height: 40px;
          }
        }

        .spoken-notions {
          &>p {
            font-size: 1rem;
            margin-bottom: 0.5rem;
          }
        }
      }
    }
  }
}